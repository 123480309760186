import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem } from "@material-ui/core";

const options = [
  {
    optionLabel: "All Data",
    optionValue: "All Data",
  },
  {
    optionLabel: "Atlanta",
    optionValue: "Atlanta",
  },
  {
    optionLabel: "Chicago",
    optionValue: "Chicago",
  },
  {
    optionLabel: "Dallas",
    optionValue: "Dallas",
  },
  {
    optionLabel: "Houston",
    optionValue: "Houston",
  },
  {
    optionLabel: "Los Angeles",
    optionValue: "Los Angeles",
  },
  {
    optionLabel: "New York",
    optionValue: "New York",
  },
  {
    optionLabel: "Philadelphia",
    optionValue: "Philadelphia",
  },
  {
    optionLabel: "San Francisco",
    optionValue: "San Francisco",
  },
  {
    optionLabel: "Seattle",
    optionValue: "Seattle",
  },
  {
    optionLabel: "Sustena",
    optionValue: "Sustena",
  },
  {
    optionLabel: "Toronto",
    optionValue: "Toronto",
  },
  {
    optionLabel: "Washington DC",
    optionValue: "Washington DC",
  },
];

const Header = (props) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
    },
    selectMenu: {
      color: "black",
    },
    selectEmpty: {
      //   marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControl component="fieldset" className={classes.formControl}>
        {/* <InputLabel className={classes.selectMenu} id="simple-select-label">Select an Office</InputLabel> */}
        <Select
          labelId="simple-select-label"
          id="simple-select"
          className={classes.selectMenu}
          value={props.officeSelected}
          onChange={(event) => props.handleChange(event.target.value)}
          // input={<OutlinedInput labelWidth={0} name={'props.label'} />}
          displayEmpty={true}
        >
          {options.map((element) => {
            return (
              <MenuItem key={element.optionValue} value={element.optionValue}>
                {element.optionLabel}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Header;

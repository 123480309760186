import React from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../utils/icons";

// let columns = [];

const EntryTable = (props) => {
  // let columns = []
  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Cohort",
      field: "level",
    },
    {
      title: "Office",
      field: "office",
    },
    {
      title: "Date",
      field: "date",
    },
    {
      title: "Event",
      field: "event",
      width: "60rem",
    },
    {
      title: "Company",
      field: "company",
    },
    {
      title: "Contact",
      field: "contact",
    },
    {
      title: "Target Account",
      field: "targetAccount",
    },
    {
      title: "Next Steps",
      field: "nextSteps",
    },
  ];

  return (
    <MaterialTable
      options={{ tableLayout: "auto", filtering: true }}
      icons={tableIcons}
      columns={columns}
      data={props.entries.length > 0 ? props.entries.sort((a,b)=>new Date(b.date) - new Date(a.date)) : []} // not sure if sort needed here
      title={`Entries for the ${props.office} office`}
    />
  );
};

export default EntryTable;

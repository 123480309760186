import React, { useEffect, useState } from "react";
import axios from "axios";
//import { Buffer } from "node:buffer";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import LineBarChart from "./components/LineBarChart";
import Header from "./components/Header";
import TopNav from "./components/TopNav";
import EntryTable from "./components/EntryTable";
import Tabs from "./components/Tabs";
import { Box, Paper, Grid, makeStyles } from "@material-ui/core";
import "date-fns";
import { ONE_DAY_IN_MILLISECONDS } from "./utils/constants";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
import CountByDateRangeTable from "./components/CountByDateRangeTable";
import { BrandThemes } from "./utils/brand-themes";

import { authProvider } from "./components/authProvider";
import testData from "./utils/testEntries.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  leftNav: {
    textAlign: "center",
  },
  grid: {
    textAlign: "left",
  },
  loadingPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
  },
  barChart: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    display: "flex",
    height: 450,
    marginTop: "10px",
  },
  lineChart: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    height: 350,
  },
  loadingGrid: {
    paddingTop: "20px",
    justifyContent: "center",
    display: "flex",
  },
}));

const requestUrl =
// window.location.hostname.indexOf("localhost") > -1
//     ? "https://qlql98hphk.execute-api.us-east-2.amazonaws.com/dev/"
//     : 
"https://jg1kf1ph1m.execute-api.us-east-2.amazonaws.com/prod/";

const App = (props) => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let [state, updateState] = useState({
    entries: null,
    fetching: false,
    office: "All Data",
    tabSelectedValue: 0,
    datePickerOpen: false,
    dateRange: {
      startDate: new Date(new Date(y, m).setHours(0, 0, 0, 0)),
      endDate: new Date(),
    },
    token: null,
    lineChart: {
      numPeriodsToCreate: 12,
      periodType: "weeks",
      viewOption: "total",
      byFinSelection: "all fins",
      lineChartStacked: "stacked",
    },
  });
  const classes = useStyles();

  const fetchEntries = async (officeName) => {
    let data;
    if (
      state.token !== null &&
      window.location.hostname.indexOf("localhost") <= -1
    ) {
      updateState({ ...state, fetching: true });
      if (officeName === "All Data") {
       data = await getDownloadData();
      } else {
        await getEntries(state.token, officeName).then(async (res) => {
          if (res && res.entries) {
            data = res.entries;
          }
        });
      }
    } else {
      data = testData.entries.sort((a, b) => new Date(a) - new Date(b));
    }
    updateState({
      ...state,
      entries: data,
      office: officeName,
      fetching: false,
      lineChart: {
        ...state.lineChart,
        byFinSelection: "all fins",
      },
    });
  };
  useEffect(() => {
    const getRefreshedToken = async () => {
      let newToken = await authProvider.getIdToken();
      return newToken;
    };
    if (state.token === null) {
      getRefreshedToken().then((newToken) => {
        if (newToken.idToken.rawIdToken !== state.token) {
          updateState({ ...state, token: newToken.idToken.rawIdToken });
        }
      });
    }

    fetchEntries(state.office);
  }, [state.token]);

  const handleOfficeChange = async (newOfficeName) => {
    await fetchEntries(newOfficeName);
  };

  const handleTabChange = (event, newValue) => {
    if (state.tabSelectedValue !== newValue) {
      updateState({ ...state, tabSelectedValue: newValue });
    }
  };

  const handleLineChartSelectChange = (event, newValue, stateToUpdate) => {
    if (state[stateToUpdate] !== newValue.props.value) {
      updateState({
        ...state,
        lineChart: {
          ...state.lineChart,
          [stateToUpdate]: newValue.props.value,
        },
      });
    }
  };

  const handleSpecialLineChartPeriodTypeChange = (
    event,
    newValue,
    stateToUpdate
  ) => {
    if (state[stateToUpdate] !== newValue.props.value) {
      updateState({
        ...state,
        lineChart: {
          ...state.lineChart,
          [stateToUpdate]: newValue.props.value,
          numPeriodsToCreate: 12,
        },
      });
    }
  };

  const getDownloadData = async () => {
    const dataToDownload =
      window.location.hostname.indexOf("localhost") > -1
        ? testData.entries
        : await axios
            .get(`${requestUrl}getData`, {
              headers: {
                Authorization: `Bearer ${state.token}`,
                "Content-Type": "application/json",
              },
            })
            .then(async (res) => await res.data)
            .catch((err) => console.log(err));

    const data =
      dataToDownload &&
      dataToDownload.entries &&
      dataToDownload.entries.length > 0
        ? dataToDownload.entries
        : testData.entries;

    return data;
  };

  const downloadFile = async () => {
    let headers = [
      "Office,Date,Event,Company,Level,Contact,Name,TargetAccount,NextSteps",
    ];

    const dataToDownload = await getDownloadData();
    // Convert users data to a csv
    let entriesCsv = dataToDownload.reduce((acc, activity) => {
      const {
        office,
        date,
        event,
        company,
        level,
        contact,
        name,
        targetAccount,
        nextSteps
      } = activity;
      acc.push(
        [
          office ?? '',
          date ?? '',
          event ? event.replace(/\r?\n/g, '') : '',
          company ?? '',
          level ?? '',
          contact ?? '',
          name ?? '',
          targetAccount ?? '',
          nextSteps ? nextSteps.replace(/\r?\n/g, '') : ''
        ].join("|")
      );
      return acc;
    }, []);
    const blob = new Blob([["sep=|", ...headers, ...entriesCsv].join("\n")], {
      type: "text/csv",
    });
    return blob;
  };
  let lineChartProps = {
    entries: state.entries ? Object.values(state.entries) : null,
    office: state.office,
    numPeriodsToCreate: state.lineChart.numPeriodsToCreate,
    periodType: state.lineChart.periodType,
    viewOption: state.lineChart.viewOption,
    byFinSelection: state.lineChart.byFinSelection,
    lineChartStacked: state.lineChart.lineChartStacked,
    handleLineChartSelectChange: handleLineChartSelectChange,
    handleSpecialLineChartPeriodTypeChange:
      handleSpecialLineChartPeriodTypeChange,
  };

  let perCompanyBarChartProps = {
    entries: state.entries ? Object.values(state.entries) : null,
    label:
      state.office !== "" &&
      `Activities by Company: ${state.dateRange.startDate.toLocaleDateString()} - ${state.dateRange.endDate.toLocaleDateString()}`,
    dateRange: state.dateRange,
    secondFilter: "company",
    type: "COMPANY",
  };

  let perFinTargetBarChartProps = {
    office: state.office,
    entries: state.entries ? Object.values(state.entries) : null,
    label:
      state.office !== "" &&
      `Activities per Fin: ${state.dateRange.startDate.toLocaleDateString()} - ${state.dateRange.endDate.toLocaleDateString()}`,
    dateRange: state.dateRange,
    secondFilter: "name",
    type: "FIN",
  };

  let entryTableProps = {
    entries: state.entries ? Object.values(state.entries) : null,
    office: state.office || null,
  };

  let tabsProps = {
    value: state.tabSelectedValue,
    handleChange: handleTabChange,
  };

  let navProps = {
    logout: props.logout(),
    downloadAllData: downloadFile,
    office: state.office,
    handleOfficeChange: handleOfficeChange,
  };

  const handleBarChartDateChange = (dateRange) => {
    let startDate = dateRange.range1.startDate;
    let endDate = dateRange.range1.endDate;
    if (typeof startDate === Date) {
      startDate.setHours(0, 0, 0, 0);
    }
    if (typeof endDate === Date) {
      endDate.setHours(0, 0, 0, 0);
    }
    let newDateRange = {
      startDate,
      endDate,
    };
    let barChartDaysPast = Math.floor(
      (endDate - startDate) / ONE_DAY_IN_MILLISECONDS
    );
    updateState({
      ...state,
      dateRange: newDateRange,
      barChartDaysPast: barChartDaysPast,
    });
  };

  return state.fetching === true ? (
    <Box display="flex" justifyContent="center">
      <Grid item xs={12} className={classes.loadingGrid}>
        <Paper className={classes.loadingPaper} elevation={3}>
          Loading Entries...
        </Paper>
      </Grid>
    </Box>
  ) : state.token !== null && state.entries !== null ? (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Grid container justify="center">
          {" "}
          {/* high level container */}
          {/* TOP BARS */}
          {(window.location.hostname.indexOf("dashboard") > -1 ||
            window.location.hostname.indexOf("localhost") > -1) && (
            <div style={{ width: "100%", background: "red" }}>
              <p style={{ color: "white", textAlign: "center" }}>
                THIS IS A TEST ENVIRONMENT WITH TEST DATA
              </p>
            </div>
          )}
          <Grid item xs={12}>
            <TopNav {...navProps} />
          </Grid>
          <Grid item xs={8} style={{ padding: 1 }}>
            <Paper elevation={1}>
              <Tabs {...tabsProps} />
            </Paper>
          </Grid>
          <Grid
            container
            spacing={1}
            style={{ maxWidth: `100%`, paddingTop: "10px" }}
          >
            {" "}
            {/* app container */}
            {/* FILTER MODAL */}
            <Grid item container spacing={1} xs={12} lg={3}>
              {" "}
              {/* date picker container / item */}
              <Grid item xs={12} className={classes.leftNav}>
                <Paper elevation={1} style={{ maxHeight: "450px" }}>
                  <Header
                    handleChange={handleOfficeChange}
                    officeSelected={state.office}
                  />
                  <DateRange
                    ranges={[state.dateRange]}
                    onChange={handleBarChartDateChange}
                    minDate={moment(
                      new Date() - 500 * ONE_DAY_IN_MILLISECONDS
                    ).toDate()}
                    maxDate={moment(new Date()).toDate()}
                    scroll={{ enabled: false }}
                    rangeColors={[BrandThemes.vibrantIndigo]}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.leftNav}>
                <Paper elevation={1}>
                  {CountByDateRangeTable(
                    state.entries,
                    state.dateRange.startDate,
                    state.dateRange.endDate
                  )}
                  {/* <Typography component="h1" color="inherit" style={{ padding: '2%' }}>
                    Lead Count of Selected Date Range: {state.filteredEntryTotal}
                  </Typography>
                    <Typography component="h1" color="inherit" style={{ padding: '2%' }}>
                      Total Activities per Cohort: 
                    </Typography> */}
                </Paper>
              </Grid>
            </Grid>
            {/* CHARTS */}
            {state.tabSelectedValue === 0 ? (
              <Grid item xs={12} lg={9}>
                {" "}
                {/* charts item */}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper className={classes.lineChart} elevation={1}>
                      {state.entries !== null ? (
                        <LineChart {...lineChartProps} />
                      ) : null}
                    </Paper>
                  </Grid>
                  <Grid item xs={state.office.includes("Los Angeles") ? 12 : 6}>
                    <Paper elevation={1} className={classes.barChart}>
                      {state.entries !== null ? (
                        <LineBarChart {...perFinTargetBarChartProps} />
                      ) : null}
                    </Paper>
                  </Grid>
                  <Grid item xs={state.office.includes("Los Angeles") ? 12 : 6}>
                    <Paper elevation={1} className={classes.barChart}>
                      {state.entries !== null ? (
                        <BarChart {...perCompanyBarChartProps} />
                      ) : null}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} lg={9}>
                {state.entries !== null ? (
                  <EntryTable {...entryTableProps} />
                ) : null}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  ) : null;
};

export const getEntries = async (accessToken, officeName) => {
  const data = {
    office: officeName,
  };
  if (officeName.includes("Los Angeles")) {
    // TODO pull the URL out to config
    return await axios
      .post(`${requestUrl}dashboardData`, data, {
        //   return await axios
        //     .get("http://localhost:3001/getLAEntries", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => await res.data)
      .catch((err) => console.log(err));
  } else {
    // TODO pull the URL out to config
    return await axios
      .post(`${requestUrl}dashboardData`, data, {
        // return await axios
        //   .post("http://localhost:3001/getEntries", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => await res.data)
      .catch((err) => console.log(err));
  }
};

export default App;

export const BrandThemes = {
    logoBlue: "#1C2C80",
    parivedaIndigo: "#1A57D6",
    vibrantIndigo: "#2C75FF",
    oceanWave: "#3BA9DA",
    vibrantWave: "#50C3EA",
    platinum: "#E9EAEC",
    white: "#FFFFFF",
    coral: "#F85352"
}

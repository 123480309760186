import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Logo from "../utils/logo.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
    display: "inline-block",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  // logout: {
  //   // justifyContent: 'flex-end'
  //   position: "absolute",
  //   left: "96%",
  //   top: "50%",
  //   // margin: 'auto',
  //   transform: "translate(-60%, -50%)",
  // },
  logo: {
    margin: "auto",
    textAlign: "left",
    maxWidth: "63%",
    maxHeight: "84%",
  },
  // logoHorizontallyCenter: {
  //   position: "absolute",
  //   left: "50%",
  //   top: "50%",
  //   transform: "translate(-50%, -50%)",
  // },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  right: {
    marginLeft: "auto",
  },
}));

export default function TopNav(props) {
  const classes = useStyles();

  const downloadData = async (e) => {
    e.preventDefault();
    const blob = await props.downloadAllData();
    const a = document.createElement("a");
    a.download = "ftf_export.csv";
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3} lg={4}>
              <img src={Logo} alt="logo" className={classes.logo} />
            </Grid>
            <Grid item xs={4} md={3} lg={4}>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                style={{ padding: "0% 20%" }}
              >
                Feed The Funnel Metrics
              </Typography>
            </Grid>
            <Grid item className={classes.right}>
              <Button variant="outlined" onClick={downloadData}>
                Download All Data
              </Button>
              <Button
                color="inherit"
                onClick={props.logout}
                style={{ marginLeft: "5px" }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer> */}
    </div>
  );
}

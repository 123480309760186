import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/be32aae3-7a3a-4fdb-b71a-161014dd062d',
        clientId: '556edff9-15bd-4a72-8c4c-df85922c9e10',
        redirectUri: `${window.location.origin}`
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        'api://5e5fa589-0f81-442c-8d46-28e36892d20a/access_as_user',
    ]
}

// Options
const options = {
    loginType: LoginType.Redirect
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
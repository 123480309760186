import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';

const RenderTabs = (props) => {
    return (
        <div>
            <Paper style={{
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0), 0px 1px 1px 0px rgba(0,0,0,0), 0px 1px 3px 0px rgba(0,0,0,0)'
            }}>
                <Tabs
                    value={props.value}
                    onChange={props.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Charts" />
                    <Tab label="Table" />
                </Tabs>
            </Paper>
        </div>
    )
}

export default RenderTabs;
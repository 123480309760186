import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const getLeadsPerCohort = (entries, startDate, endDate, leadsPerCohort) => {
    if (entries !== null && entries.length > 0) {
        let entryDate = new Date(entries[0].date);
        entries.forEach((entry) => {
            entryDate = new Date(entry.date);
            if (
                entryDate >= startDate &&
                entryDate <= endDate
            ) {
                leadsPerCohort.total++;
                if (entry.level.includes("Vice President")) {
                    leadsPerCohort.v++;
                } else if (entry.level.includes("Principal")) {
                    leadsPerCohort.p++;
                } else if (entry.level.includes("Manager")) {
                    leadsPerCohort.m++;
                } else if (entry.level.includes("Associate")) {
                    leadsPerCohort.a++;
                } else if (entry.level.includes("Consultant")) {
                    leadsPerCohort.c++;
                }
            }
        });
    }
    return leadsPerCohort;
}

const createData = (name, leadCount) => {
    return { name, leadCount };
}

const CountByDateRangeTable = (entries, startDate, endDate) => {
    let leadsPerCohort = {
        v: 0,
        p: 0,
        m: 0,
        a: 0,
        c: 0,
        total: 0
    };

    leadsPerCohort = getLeadsPerCohort(entries, startDate, endDate, leadsPerCohort);

    const rows = [
        createData('Vice President', leadsPerCohort.v),
        createData('Principal', leadsPerCohort.p),
        createData('Manager', leadsPerCohort.m),
        createData('Associate', leadsPerCohort.a),
        createData('Consultant', leadsPerCohort.c),
        createData('Total', leadsPerCohort.total),
    ];

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>Cohort</TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>Lead Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row" style={{ fontWeight: row.name === "Total" ? "bold" : null }}>
                                {row.name}
                            </TableCell>
                            <TableCell align="right" style={{ fontWeight: row.name === "Total" ? "bold" : null }}>{row.leadCount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CountByDateRangeTable;
import React from "react";
import App from "../App";

import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './authProvider';

let appProps = {
  logout: null
}

export default function Root() {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {
        ({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              appProps = {...appProps, logout: () => logout}
              return (
                <div>
                  <App {...appProps} />
                </div>
              );
            case AuthenticationState.Unauthenticated:
              return (
                <div>
                  {error && <p><span>An error occured during authentication, please try again!</span></p>}
                </div>
              );
            case AuthenticationState.InProgress:
              return (
                null
              );
          }
        }
      }
    </AzureAD>
  );
}
